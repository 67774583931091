import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"

import { Helmet } from "react-helmet"

import Header from "../components/header/header"
import Paragraph from "../components/paragraph/paragraph"
import Accreditations from "../components/accreditations/accreditations"
import Enquire from "../components/enquire/enquire"
import CTAList from "../components/cta-list/cta-list"
import CardSlider from "../components/card-slider/card-slider"
import Timeline from "../components/timeline/timeline"
import QuestionList from "../components/question-list/question-list"
import PriceCard from "../components/price-card/price-card"
import ReviewComponent from "../components/review-component/reviewComponent"
import ImageComponent from "../components/image-component/image-component"



// markup
const PrivacyPolicy = () => {

    const data = useStaticQuery(graphql`
        {
            strapiPrivacyPolicy {
                PageComponents
                Seo {
                  metaDescription
                  metaTitle
                }
              }
              allFile {
                edges {
                  node {
                    publicURL
                    id
                    name
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 1900) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
        }
    `)





    function getImgUrl(localFile___NODE) {

        var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

        data.allFile.edges.map((item, index) => {

            if (item.node.id === localFile___NODE) {
                // if(item.node.publicURL.endsWith(".svg")){
                //   imgUrl.basicUrl = item.node.publicURL
                // } else {
                //   imgUrl.fluid = item.node.childImageSharp.fluid
                //   imgUrl.basicUrl = item.node.publicURL
                // }
                imgUrl.imgName = item.node.name ? item.node.name : null
                imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
                imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
                imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null

                // imgUrl = item.node.publicURL
            }
            return ("")

        })
        // console.log(imgUrl)
        return (imgUrl)
    }


    // const { siteUrl: url } = useSiteMetadata()

    // let richSnippetData = {
    //   '@context': "https://schema.org",
    //   "@type": "Organization",
    //   name: `${data.strapiSiteSettings.SiteTitle}`,
    //   url: `${url}`,
    //   logo: `${url + data.strapiSiteSettings.LogoDark.localFile.publicURL}`,
    //   sameAs: [data.strapiSiteSettings.SocialLinks.FacebookURL, data.strapiSiteSettings.SocialLinks.TwitterURL, data.strapiSiteSettings.SocialLinks.LinkedinURL, data.strapiSiteSettings.SocialLinks.YoutubeURL]
    // }



    return (
        <Layout SEOTitle={data.strapiPrivacyPolicy.Seo.metaTitle} SEODescription={data.strapiPrivacyPolicy.Seo.metaDescription} footerDisclaimer={data.strapiPrivacyPolicy.FooterDisclaimer}>

            {/** List of meta properties coming from strapi that applies only for the homepage */
                <Helmet>
                    {data.strapiPrivacyPolicy.MetaTags?.map((item, index) => {
                        return (
                            <>

                                <meta key={`meta-key-` + index} name={item.MetaName} content={item.MetaContent} />
                            </>
                        )
                    })}
                </Helmet>
            }

            {data.strapiPrivacyPolicy?.PageComponents?.map((item, index) => {


                switch (item.strapi_component) {

                    case "page-components.header":
                        return (
                            <>
                                {/* <RichSnippet type={"Organization"} data={richSnippetData} /> */}
                                <Header
                                    key={index}
                                    Title={item.Title}
                                    Subtitle={item.Subtitle}
                                    FirstCTALabel={item.FirstCTALabel}
                                    FirstCTAURL={item.FirstCTAURL}
                                    Background={item.Background !== null ? getImgUrl(item.Background.localFile___NODE) : ""}
                                    Image={item.Image !== null ? getImgUrl(item.Image.localFile___NODE) : null}
                                    BackgroundColor={item.BackgroundColorHeader}
                                ></Header>
                            </>

                        )
                    case "page-components.paragraph":
                        return (
                            <Paragraph
                                key={index}
                                Content={item.Content}
                                Image={item.Image !== null ? getImgUrl(item.Image.localFile___NODE) : null}
                                ImagePosition={item.ImagePosition}
                                BackgroundColor={item.BackgroundColor}
                                PolygonBottom={item.PolygonBottom}
                                PolygonTop={item.PolygonTop}
                                PaddingTop={item.PaddingTop}
                                PaddingBottom={item.PaddingBottom}
                                Background={item.Background !== null ? getImgUrl(item.Background.localFile___NODE) : ""}
                                GradientColor={item.GradientColor}

                            />
                        )
                    case "page-components.slider-image":
                        let images = [];
                        item.image.map((item, index) => {

                            let imageURL = getImgUrl(item.Picture.localFile___NODE);
                            images.push({
                                Alt: item.Alt,
                                Image: imageURL
                            });
                        })
                        return (
                            <Accreditations
                                key={index}
                                Images={images}
                                Description={item.Description}
                            />
                        )

                    case "page-components.enquire":
                        return (
                            <Enquire
                                key={index}
                                Title={item.Title}
                                Subtitle={item.Subtitle}
                                FooterText={item.FooterText}
                                CTALabel={item.CTALabel}
                                CTAUrl={item.CTAUrl}
                                Background={item.Background !== null ? getImgUrl(item.Background[0]?.localFile___NODE) : ""}
                            />
                        )
                    case "page-components.our-services":
                        let cards = [];
                        item.ServiceCard.map((item, index) => {
                            let Image = getImgUrl(item.Image.localFile___NODE);
                            cards.push({
                                Title: item.Title,
                                Subtitle: item.Subtitle,
                                CTALabel: item.CTALabel,
                                CTAUrl: item.CTAUrl,
                                TextColor: item.TextColor,
                                Image: Image
                            })
                        })
                        return (
                            <CTAList
                                key={index}
                                Type="our-service"
                                Cards={cards}
                                Content={item.Content}
                                FooterText={item.FooterText}
                                BackgroundColor={item.BackgroundColor}
                                PolygonBottom={item.PolygonBottom}
                                PolygonTop={item.PolygonTop}
                            />
                        )
                    case "page-components.card-slider":
                        let sliderCards = [];
                        item.Card.map((item, index) => {
                            let Image = getImgUrl(item.Image.localFile___NODE);
                            sliderCards.push({
                                Title: item.Title,
                                Subtitle: item.Subtitle,
                                Image: Image
                            })

                        })
                        return (
                            <CardSlider
                                key={index}
                                Content={item.Content}
                                Cards={sliderCards} />
                        )
                    case "page-components.timeline":
                        let timelineCards = [];
                        item.Card.map((item, index) => {
                            let Image = getImgUrl(item.Image.localFile___NODE);
                            timelineCards.push({
                                Title: item.Title,
                                Subtitle: item.Subtitle,
                                Image: Image
                            })
                        })

                        return (
                            <Timeline
                                key={index}
                                Content={item.Content}
                                Cards={timelineCards} />
                        )

                    case "page-components.fa-qs":
                        return (
                            <QuestionList
                                key={index}
                                Title={item.Title}
                                Question={item.Question} />
                        )

                    case "page-components.price-component":
                        let serviceCards = [];
                        item.price_services.map((item, index) => {
                            let Image = getImgUrl(item.Image.localFile___NODE);
                            serviceCards.push({
                                Image: Image,
                                Title: item.Title,
                                Description: item.Description,
                                Price: item.Price
                            })
                        })

                        return (
                            <PriceCard
                                key={index}
                                Type="price-component"
                                Discount={data.strapiGlobal.Discount}
                                Services={serviceCards}
                                BackgroundColor={item.BackgroundColor}
                                PolygonBottom={item.PolygonBottom}
                                PolygonTop={item.PolygonTop}
                            />
                        )

                    case "page-components.review-component":
                        let reviewCards = [];
                        item.reviews.map((item, index) => {
                            let Image = getImgUrl(item.Image.localFile___NODE);
                            reviewCards.push({
                                Title: item.Title,
                                Description: item.Description,
                                Author: item.Author,
                                Image: Image,
                                Alt: item.Alt
                            })
                        })

                        return (
                            <ReviewComponent
                                key={index}
                                Content={item.Content}
                                Cards={reviewCards}
                                Button={item.Button}
                            />
                        )

                    case "page-components.image-component":
                        let imgCards = [];
                        item.image_cards.map((item, index) => {
                            let Image = getImgUrl(item.Image.localFile___NODE);
                            imgCards.push({
                                Image: item.Image
                            })
                        })

                        return (
                            <ImageComponent
                                key={index}
                                Images={imgCards}
                            />
                        )




                    default:
                        return ("")
                }
            })}














        </Layout >
    )
}

export default PrivacyPolicy