import React from 'react';
import './reviewComponent.scss';
import CustomMarkdown from "../../utils/customMarkdown";
import ReviewCard from '../review-card/review-card';


const ReviewComponent = ({ Content, Cards, Button }) => {

    return (

        <div className="uk-slider uk-slider-container review-slider-container" uk-slider="true">
            {
                Content ? <CustomMarkdown children={Content} className="description-markdown"></CustomMarkdown> : ""
            }

            <div className="uk-position-relative uk-visible-toggle slider-content" tabIndex="-1">

                <div className="uk-slider-container uk-light">
                    
                    <ul className="uk-slider-items uk-child-width-1-1@xs uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-match uk-grid">
                        {
                            Cards.map((item, index) => {
                                return (
                                    <li key={index} className="cards-review">
                                        <ReviewCard
                                            key={index}
                                            Title={item.Title}
                                            Description={item.Description}
                                            Author={item.Author}
                                            Image={item.Image}
                                            Alt={item.Alt}
                                        />
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>

                <div className="uk-hidden@s uk-light">
                    <a className="uk-position-center-left uk-position-small icon-arrow" href="#" data-uk-slidenav-previous="true" uk-slider-item="previous" aria-label="slide-prev"></a>
                    <a className="uk-position-center-right uk-position-small icon-arrow" href="#" data-uk-slidenav-next="true" uk-slider-item="next" aria-label="slide-next"></a>
                </div>

                <div className="uk-visible@s">
                    <a className="uk-position-center-left-out uk-position-small icon-arrow" href="#" data-uk-slidenav-previous="true" uk-slider-item="previous" aria-label="slide-prev"></a>
                    <a className="uk-position-center-right-out uk-position-small icon-arrow" href="#" data-uk-slidenav-next="true" uk-slider-item="next" aria-label="slide-next"></a>
                </div>

            </div>

            {/* <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul> */}
            <CustomMarkdown children={Button} className="review-button"></CustomMarkdown>

        </div>
    )
}

export default ReviewComponent;