import React from 'react';
import './review-card.scss';
import { GatsbyImage } from "gatsby-plugin-image";
import CustomMarkdown from "../../utils/customMarkdown";
import reviews from "../../assets/reviews-tower-heating.webp";


const ReviewCard = ({ Title, Description, Author, Image, Alt }) => {

    return (

        <div className="card-container">
            <div className="uk-card uk-card-body" id="card-content">
                <h2 className="uk-card-title">{Title}</h2>
                <img src={reviews} alt="review-stars" className="center" />
                <CustomMarkdown children={Description} className="des"></CustomMarkdown>
                <p className="author">{Author}</p>
                {
                    Image.localFile ?
                        <GatsbyImage image={Image?.localFile.childImageSharp.gatsbyImageData} alt={Alt}></GatsbyImage>
                        :
                        <GatsbyImage image={Image?.gatsbyImageData} alt={Alt}></GatsbyImage>
                }
            </div>
        </div>
    )
}

export default ReviewCard;